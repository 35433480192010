<template>  
  <v-main>
    <v-container fill-height>
        <v-row justify="center" align="center" no-gutters class="grey">
          <v-col cols="7" align="center" xs12 sm8 md8 lg8 class="grey lighten-5 pa-10">
            <div class="mb-7 text-h2 text-center">The event help desk is currently closed, try again later.</div>
            <v-img style="max-height: 300px" src="/sleeping.png"></v-img>
            <v-form ref="form">
              <div height="100px" class="mt-0" dense></div>
            </v-form>
            <div class="pt-3"></div>
          </v-col>
        </v-row>
      </v-container>
  </v-main>
</template>